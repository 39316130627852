import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import { useLocation } from 'react-router-dom';
import PageTitle from '../Universal/PageTitle'
import Wrapper from '../Universal/Wrapper/Index'
import UpdateForm from './Form'
import List from './List'
import { postAPI } from '../../../../utils/Api';
import { store } from '../../../../app/store'

import FormSubmissionHandler from '../../../../UIComponents/FormSubmissionHandler'
import InputHandler from '../../../../UIComponents/InputHandler'
import SelectBoxHandler from '../../../../UIComponents/SelectBoxHandler'



export default function Index({ Title = 'Call Logs', Type = "LIST" }) {
  const { id } = useParams();
  const user = store.getState().auth.user;
  const location = useLocation();
  const InitialFilterData = {
    org_id: user?.org_id,
  }
  const [filter_data, setFilterData] = useState(InitialFilterData);
  const [editing, setEditing] = useState(false);
  const [error, setError] = useState({});

  // Initial Data
  const InitialActions = [{
    //   id: 1,
    //   name: 'Status',
    //   type: 'TOGGLE',
    //   color: 'success',
    //   ajaxUrl: `/Calllog/toggleStatus`,
    // }, {
    id: 2,
    name: 'View',
    type: 'BUTTON',
    color: 'primary',
    ajaxUrl: `/View/`,
    // }, {
    //   id: 3,
    //   name: 'Edit',
    //   type: 'BUTTON',
    //   color: 'warning',
    //   ajaxUrl: `/Edit/`,
    // }, {
    //   id: 4,
    //   name: 'Remove',
    //   type: 'BUTTON',
    //   color: 'danger',
    //   ajaxUrl: `/Calllog/remove`,
  }];
  const InitialTableHeaders = [{
    id: 1,
    key: 'id',
    name: '#',
    datatype: 'id',
    // }, {
    //   id: 2,
    //   key: 'image',
    //   name: 'Image',
    //   datatype: 'image',
  }, {
    id: 3,
    key: 'received_from',
    name: 'From',
    datatype: 'text',
  }, {
    id: 4,
    key: 'type',
    name: 'Type',
    datatype: 'text',
  }, {
    id: 5,
    key: 'name',
    name: 'Name',
    datatype: 'text',
  }, {
    id: 6,
    key: 'number',
    name: 'Contact No',
    datatype: 'text',
  }, {
    id: 7,
    key: 'duration',
    name: 'Duration',
    datatype: 'text',
  }, {
    id: 8,
    key: 'timestamp',
    name: 'Date Time',
    datatype: 'timestamp',
  }];
  const InitialData = [];
  // Initial Data

  const [FormVisibility, setFormVisibility] = useState(id ? true : false);
  const [PageType, setPageType] = useState(id ? 'EDIT' : Type);
  const [Actions, setActions] = useState(InitialActions);
  const [TableHeaders, setTableHeaders] = useState(InitialTableHeaders);
  const [Data, setData] = useState(InitialData);
  const [Staff, setStaff] = useState({});

  useEffect(async () => {
    const filter = {
      org_id: user?.org_id,
    }
    const data = await postAPI('/Calllog/list', filter);
    setData(data?.data)
    const staff_data = await postAPI('/Staff/list', filter);
    console.log(staff_data?.data)
    const staff_arr = [];
    for (let index = 0; index < staff_data?.data?.length; index++) {
      staff_arr.push({
        value: staff_data?.data[index]['user_unique_id'],
        label: staff_data?.data[index]['name'],
      })
    }
    setStaff(staff_arr);
  }, [user])

  const handleChangeVisibility = e => {
    setPageType(!FormVisibility ? 'ADD' : Type)
    setFormVisibility(!FormVisibility);
  }


  const handleSubmit = async e => {
    e.preventDefault()
    const filter = {
      org_id: user?.org_id,
      ...filter_data
    }
    const data = await postAPI('/Calllog/filterlist', filter);
    setData(data?.data)
  }
  return (
    <Wrapper>
      <div className="d-flex justify-content-start mb-3">
        <span>Home &nbsp; </span>
        <span> &gt; {location.pathname.replace("/", '')}</span>
      </div>
      <PageTitle PageType={PageType} Title={Title}>
        {/* <button type="button" className="btn btn-primary mb-3" onClick={handleChangeVisibility}>
        {
        !FormVisibility ? 'ADD' : `Back To ${Title}` 
        }
        </button> */}
      </PageTitle>
      {
        FormVisibility ?
          <UpdateForm />
          :
          <div className='container-fluid'>
            <div className="card mb-3">
              <div className="card-body">
                <FormSubmissionHandler handleSubmit={handleSubmit} editing={editing} error={error} filter={1}>
                  <div className='container-fluid'>
                    <div className='row'>
                      <div className='col'>
                        <SelectBoxHandler Options={Staff} form_data={filter_data} setFormData={setFilterData} Name="user_unique_id" Type="text" Label="Staff"></SelectBoxHandler>
                      </div>
                      <div className='col'>
                        <InputHandler form_data={filter_data} setFormData={setFilterData} Name="start_date" Type="date" Label="Start Date"></InputHandler>
                      </div>
                      <div className='col'>
                        <InputHandler form_data={filter_data} setFormData={setFilterData} Name="end_date" Type="date" Label="End Date"></InputHandler>
                      </div>
                    </div>
                  </div>
                </FormSubmissionHandler>
              </div>
            </div>
            <List Location={location} TableHeaders={TableHeaders} Data={Data} Actions={Actions} />
          </div>
      }
    </Wrapper>
  )
}
