import React, { useEffect, useState } from 'react'
import { Col } from 'react-bootstrap'

export default function FormSubmissionHandler({ children, handleSubmit, editing, error, filter=0 }) {
  return (
    <form onSubmit={handleSubmit}>
      {children}
      <hr />
      <Col md='12'>
        <div className='btn-box'>
          <button type="submit" className='btn btn-primary'>{filter ? 'Search' : editing ? 'Update' : 'Save'}</button>
        </div>
        {error && (<p style={{ color: error.status === 0 ? 'red' : 'green' }}>{error.msg}</p>)}
      </Col>
    </form>
  )
}
